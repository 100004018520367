<template>
  <!-- <div class="poster-box" id="posterHtml2">
    <img class="bg-img" :src="poster" alt="" >
    
    <div class="profile">
      <div class="headimgurl">
        <img :src="headimgurl" alt="" >
      </div>
      <div class="nickname">{{nickname}}</div>
    </div>
    
    <div class="qrcode" >
      <vue-qr class="qrcode-img" :text="qrcode_uri" :size="100" :margin="2"></vue-qr>
    </div>
  </div> -->

  <div
      class="poster-box"
      id="posterHtml2"
  >
    <div
        class="img-box"
        id="imgBox"
    >
      <img
          class="bg-img"
          style="max-width:500px;display: block"
          :src="posterImg"
          alt=""
      >

      <div class="profile">
        <div
            class="headimgurl"
            :style="`width:${imgSize.width*0.12}px;height:${imgSize.width*0.12}px`"
        >
          <img
              style="width:100%;height:100%"
              :src="headimgurl"
              alt=""
          >
        </div>
        <div
            class="nickname"
            :style="`font-size:${imgSize.width*0.037}px;margin-left:10px`"
        >{{ nickname }}
        </div>
      </div>

      <div
          v-if="qrcodeRe"
          class="qrcode"
          :style="`width:${imgSize.width*0.3}px;height:${imgSize.width*0.3}px;display:flex;justify-content:center;align-items:center;`"
      >
        <vue-qr
            class="qrcode-img"
            :text="qrcode_uri"
            :size="imgSize.width*0.20"
            :margin="2"
        ></vue-qr>
      </div>
    </div>

    <Loading :loading="loading"></Loading>

  </div>

</template>

<script>
import VueQr from "vue-qr";
import html2canvas from "html2canvas";
import {getPosterInfo, getPosterQRUrl, savePosterImg} from "../api/poster";

export default {
  components: {
    VueQr
  },
  data() {
    return {
      loading: false,
      // refer: "",
      headimgurl: "https://ceshiren.com/uploads/default/original/1X/809c63f904a37bc0c6f029bbaf4903c27f03ea8a.png",
      nickname: "霍格沃兹测试学院",
      posterImg: "",
      qrcode_uri: "",
      title: '',
      // origin_: '',
      // project: '',
      project_name: "",
      unionid: "",
      imgSize:{
        width:375,
        height:375
      },
      qrcodeRe:false
    }
  },
 async created() {
    // this.origin_ = location.origin
    // 保存当前访问中携带的参数数据
    this.project_name = this.$route.params.project_name
    this.unionid = this.$route.query.unionid
    // console.log(this.$route.params.project_name, this.$route.query.unionid);
    console.log(this.project_name, this.unionid);
    await this.getUserInfo()
  },
  async mounted() {
    // await this.getUserInfo()
    // setTimeout(this.saveHtml2Image, 1500);
    document.title = this.title
  },
  methods: {
    //获取用户数据信息
    async getUserInfo() {
      //获取页面参数
      // let project = this.$route.query.project
      // this.project = this.$route.query.project
      // let refer = this.$route.query.refer
      // let referrer = this.$route.query.referrer
      // let bannner = this.$route.query.bannner
      // let arr = [{ project:this.project }, { refer:this.refer }, { referrer: }, { bannner }]
      // let params = {}
      // // 将已有参数添加到params对象中
      // arr.forEach(item => {
      //   if (item[Object.keys(item)[0]]) {
      //     params[Object.keys(item)[0]] = item[Object.keys(item)[0]]
      //   }
      // })


      //将发送参数进行加密，加密结果统一通过data字符串发送
      // const res = await axios.post(`${this.origin_}/backend/poster`, {data: this.$resCommon.reqEncrypt(params)})
      // const res = await axios.get(`${this.origin_}/backend/poster/${this.project_name}`, { params })

      // const res = await axios.post(`http://localhost:5000/poster`, {data: this.$resCommon.reqEncrypt(params)})
      // const res = await axios.get('https://dev.poster.ceba.ceshiren.com/backend/dev/poster', { params })
      // console.log(res);
      // 返回结果是加密的，需要解密后再读取参数
      // const resData = this.$resCommon.getResData(res);
      // const resData = res.data.data;
      // this.title = resData.title
      // this.posterImg = resData.poster
      // this.qrcode_uri = resData.qrcode_uri
      // this.img_exist = resData.img_exist
      // if (resData.headimgurl) {
      //   console.log(123);
      //   this.headimgurl = resData.headimgurl
      // }
      // if (resData.nickname) {
      //   console.log(456);
      //   this.nickname = resData.nickname
      // }
      //开启loading动画
      this.loading = true
      let params = {unionid: this.unionid}
      if (this.$route.query.nickname) {
        params.nickname = this.$route.query.nickname
      }
      if (this.$route.query.headimgurl) {
        params.headimgurl = this.$route.query.headimgurl
      }
      if (this.$route.query.source) {
        params.source = this.$route.query.source
      }
      const posterInfo = await getPosterInfo(params, this.project_name)
      this.loadPosterInfoFromRes(posterInfo)
      const qrUrl = await getPosterQRUrl({unionid: this.unionid}, this.project_name)
      this.qrcode_uri = qrUrl.data.data.url
      this.$nextTick(()=>{
        let img = document.querySelector('.bg-img')
        console.log(img)
        if (img.complete) {
          this.getImgSize()
        }else{
          img.addEventListener('load', this.getImgSize)
        }
      })
      
    },
    // 保存PosterInfo接口返回的数据
    loadPosterInfoFromRes(posterInfo) {
      const resData = posterInfo.data.data;
      this.title = resData.title
      this.posterImg = resData.poster_img
      this.img_exist = resData.img_exist
      if (resData.headimgurl) {
        console.log("头像读取成功")
        this.headimgurl = resData.headimgurl
      }
      if (resData.nickname) {
        console.log("昵称读取成功");
        this.nickname = resData.nickname
      }
      
    },

    getImgSize(){
      console.log('test5');
      // 读取img宽度赋值
      console.log(document.getElementById('imgBox').offsetWidth);
      this.imgSize.width=document.getElementById('imgBox').offsetWidth
      this.qrcodeRe = true
      this.$nextTick(()=>{
        setTimeout(this.saveHtml2Image, 1000);
      })
    },

    //将html结构转换为图片
    saveHtml2Image() {
      //转换图片时 将高度归0 避免出现顶部白边
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;

      html2canvas(document.querySelector('#imgBox'), {
        useCORS: true,
        allowTaint: true,
      }).then(async canvas => {
        const img = document.createElement("img");
        let src = canvas.toDataURL("image/jpeg", 0.9)
        img.src = src
        document.body.appendChild(img);
        img.style.cssText =
            "width:100%;height:auto;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
        img.id = "poster-Img-new"
        //将base64符号转义处理 
        // encodeURIComponent(img.src)
        //转换完成后 将base64提交到后台
        const data = {img: src, unionid: this.unionid}
        console.log(data)
        this.loading = false
        if (this.img_exist) {
          return
        }
        // axios.post(`${this.origin_}/backend/recv_img`, { img: src, refer: this.refer, project: this.project })
        // axios.post('https://dev.poster.ceba.ceshiren.com/backend/dev/recv_img', { img: src, refer: this.refer })
        // .then(res => {
        //   console.log(res);
        // })
        savePosterImg(data, this.project_name)
      });
    },
  }
}
</script>

<style scoped>
.poster-box {
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  overflow: hidden;
  overflow-x: hidden;
}

.img-box {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.bg-img {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.profile {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  /* top: 15px;
  left: 12px; */
  top: 2%;
  left: 5%;
}

.profile .headimgurl {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}

.profile .nickname {
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
}

.profile .headimgurl img {
  width: 100%;
}

.qrcode {
  /* width: 2rem;
  height: 2rem; */
  position: absolute;
  /* right: 1rem;
  bottom: 0.3rem; */
  z-index: 2;
  right: 0%;
  bottom: 0%;
}

.qrcode .qrcode-img {
  /* width: 100%;
  height: 100%; */
}
</style>