import request from "./http";

export const getPosterQRUrl = async (params, project_name) => await request({
    method: 'get',
    url: `/poster_qr_url/${project_name}`,
    params: params
})
export const getPosterInfo = async (params, project_name) => await request({
    method: 'get',
    url: `/poster/${project_name}`,
    params: params
})
export const savePosterImg = (data, project_name) => request({
    method: 'post',
    url: `/recv_img/${project_name}`,
    data: data
})
